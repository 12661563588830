:root {
  --rmdp-primary: #ffc132;
  --rmdp-secondary: #4ca6f5;
  --rmdp-shadow: #8798ad;
  --rmdp-today: #7fdbff;
  --rmdp-hover: #E4913F;
  --rmdp-border: #cfd8e2;
  --highlight-red-color: #cc0303;
  --highlight-red-color-deactive: #e08e8e;
  --highlight-red-selected: #ea0034;
  --highlight-red-selected-deactive: #e4b0ba;
  --highlight-red-hover: #ff6687;
  --highlight-green-color: #00796b;
  --highlight-green-color-deactive: #7ab3ac;
  --highlight-green-selected: #009688;
  --highlight-green-selected-deactive: #749c98;
  --highlight-green-hover: #4db6ac;
}

.rmdp-wrapper {
  width: max-content;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  direction: ltr;
}

.rmdp-shadow {
  box-shadow: 0 0 5px var(--rmdp-shadow);
}

.rmdp-border {
  border: 1px solid var(--rmdp-border);
}

.rmdp-calendar {
  padding: 4px;
  height: max-content;
}

.rmdp-border-top {
  border-top: 1px solid var(--rmdp-border);
}

.rmdp-border-bottom {
  border-bottom: 1px solid var(--rmdp-border);
}

.rmdp-border-left {
  border-left: 1px solid var(--rmdp-border);
}

.rmdp-border-right {
  border-right: 1px solid var(--rmdp-border);
}

.rmdp-week,
.rmdp-ym {
  display: flex;
  justify-content: space-between;
}

.rmdp-ym {
  height: 25%;
}

.rmdp-day,
.rmdp-week-day {
  height: 34px;
  width: 34px;
  cursor: pointer;
  position: relative;
  color: black;
    background: transparent;
  border: none;
  border-radius: 50%;
}

.rmdp-week-day {
  cursor: default;
  color: var(--rmdp-primary);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.rmdp-day span {
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

.rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today);
  color: white;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
  color: white;
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
  color: var(--rmdp-shadow);
}

.rmdp-day.rmdp-deactive.rmdp-selected span {
  background-color: var(--rmdp-secondary);
  box-shadow: 0 0 3px #bac5d3;
}

.rmdp-ym .rmdp-day {
  flex: 1;
  margin: auto;
}

.rmdp-ym .rmdp-day span {
  border-radius: 12px;
  padding: 2px 0px;
}

.rmdp-range {
  color: white;
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-range-hover {
  background-color: var(--rmdp-hover);
  color: white;
}

.rmdp-range.start,
.rmdp-range-hover.start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rmdp-range.end,
.rmdp-range-hover.end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rmdp-ym .rmdp-range.start,
.rmdp-ym .rmdp-range-hover.start {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rmdp-ym .rmdp-range.end,
.rmdp-ym .rmdp-range-hover.end {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover);
  color: white;
}

.rmdp-day-picker {
  padding: 5px;
}

.rmdp-header {
  margin-top: 5px;
  font-size: 14px;
  padding: 9px 0;
}

.rmdp-month-picker,
.rmdp-year-picker {
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 0 0 5px 5px;
}

.only.rmdp-month-picker,
.only.rmdp-year-picker {
  position: static;
  height: 240px;
}

.only.rmdp-month-picker .rmdp-day,
.only.rmdp-year-picker .rmdp-day {
  width: 80px;
}

.rmdp-header-values {
  color: black;
  margin: auto;
}

.rmdp-header-values span {
  padding: 0 5px;
  padding-right: 0;
}

.rmdp-arrow {
  border: solid var(--rmdp-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  height: 3px;
  width: 3px;
  margin-top: 5px;
}

.rmdp-right i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 3px;
}

.rmdp-left i {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 3px;
}

.rmdp-left {
  left: 0;
}

.rmdp-right {
  right: 0;
}

.rmdp-arrow-container {
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  background: transparent;
  border: none;
}

.rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
}

.rmdp-arrow-container.disabled {
  cursor: default;
}

.rmdp-arrow-container.disabled:hover {
  background-color: inherit;
  box-shadow: inherit;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid gray;
  border-width: 0 2px 2px 0;
}

.rmdp-rtl {
  direction: rtl;
}

.rmdp-rtl .rmdp-left i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 0;
  margin-right: 3px;
}

.rmdp-rtl .rmdp-right i {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 0;
  margin-left: 3px;
}

.rmdp-rtl .rmdp-right {
  right: auto;
  left: 0;
}

.rmdp-rtl .rmdp-left {
  left: auto;
  right: 0;
}

.rmdp-rtl .rmdp-range.start,
.rmdp-rtl .rmdp-range-hover.start {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rmdp-rtl .rmdp-range.end,
.rmdp-rtl .rmdp-range-hover.end {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rmdp-rtl .rmdp-range.start.end {
  border-radius: 50%;
}

.rmdp-rtl .rmdp-ym .rmdp-range.start,
.rmdp-rtl .rmdp-ym .rmdp-range-hover.start {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.rmdp-rtl .rmdp-ym .rmdp-range.end,
.rmdp-rtl .rmdp-ym .rmdp-range-hover.end {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rmdp-day-hidden,
.rmdp-day.rmdp-disabled {
  cursor: default;
}

.rmdp-selected .highlight {
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-red:hover {
  background-color: var(--highlight-red-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
  color: var(--highlight-red-color);
}

.rmdp-day.rmdp-deactive .highlight-red {
  color: var(--highlight-red-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected);
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected-deactive);
  color: white;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-green:hover {
  background-color: var(--highlight-green-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-green {
  color: var(--highlight-green-color);
}

.rmdp-day.rmdp-deactive .highlight-green {
  color: var(--highlight-green-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-green {
  background-color: var(--highlight-green-selected);
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-green {
  background-color: var(--highlight-green-selected-deactive);
  color: white;
}

.rmdp-day-hidden:hover span,
.rmdp-day-hidden {
  background-color: unset;
  color: transparent;
}

.rmdp-month-name {
  font-size: 14px;
  margin: 3px 0;
  cursor: default;
}

.rmdp-full-year {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 450px), (max-height: 450px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 28px;
    width: 28px;
  }

  .rmdp-day span {
    padding-left: 0.5px;
    font-size: 12px;
  }

  .only.rmdp-year-picker,
  .only.rmdp-month-picker {
    width: 205px;
    height: 200px;
  }

  .rmdp-header {
    padding: 3px 0 0 0;
    font-size: 12px;
  }

  .rmdp-month-name {
    font-size: 12px;
  }

  .rmdp-full-year {
    grid-template-columns: 1fr 1fr;
  }


}
