:root {
  --rmdp-primary: #ffc132;
  --rmdp-shadow: #8798ad;
  --highlight-red-selected: #ea0034;
  --highlight-green-selected: #009688;
}

.rmdp-panel {
  min-width: 125px;
}

.rmdp-panel-body {
  position: absolute;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0px 5px;
  margin: 0;
  text-align: center;
  overflow: auto;
}

.rmdp-panel-body li {
  border-radius: 3px;
  box-shadow: 0 0 2px var(--rmdp-shadow);
  background-color: var(--rmdp-primary);
  color: white;
  position: relative;
  margin: 4px 1px;
}

.rmdp-panel-body li.bg-blue {
  background-color: #0074d9;
}

.rmdp-panel-body li.bg-red {
  background-color: var(--highlight-red-selected);
}

.rmdp-panel-body li.bg-green {
  background-color: var(--highlight-green-selected);
}

.rmdp-panel-body li.bg-yellow {
  background-color: #fad817;
}

.rmdp-panel-body li.rmdp-focused {
  box-shadow: 0 0 2px 1px var(--rmdp-shadow);
}

.rmdp-panel-body span {
  margin: 0 5px;
  line-height: 23px;
  font-size: 15px;
  text-align: start;
  flex: 1;
}

.rmdp-panel-body li .b-deselect {
  font-family: Arial !important;
  align-items: center;
  display: inline-flex;
  border: none;
  cursor: pointer;
  height: 12px;
  width: 12px;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 52%;
  transform: translateY(-50%) rotate(45deg);
  padding: 0;
  line-height: 5px;
  background: transparent;
}

.rmdp-panel-body li .b-date {
  border: none;
  background-color: var(--rmdp-primary);
  font-size: 14px;
}

.rmdp-panel-body li .b-deselect:focus {
  outline: none;
}

.rmdp-panel-header {
  margin-top: 8px;
  height: 38px;
  line-height: 37px;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.rmdp-panel-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary);
  border-radius: 3px;
}

.rmdp-panel-body::-webkit-scrollbar-thumb:hover {
  background: #0263b8;
}

.rmdp-rtl .rmdp-panel-body li .b-deselect {
  right: unset;
  left: 5px;
}

.rmdp-flat .rmdp-panel-header {
  border-bottom: none;
  margin-top: 8px;
}

.rmdp-flat .rmdp-panel-body li {
  border-radius: 0;
}

@media (max-width: 400px), (max-height: 400px) {
  .rmdp-panel {
    min-width: 103px;
  }

  .rmdp-panel-body {
    padding: 0 2px;
  }

  .rmdp-panel-body li {
    font-size: 12px;
    margin: 4px;
  }

  .rmdp-panel-header {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .rmdp-panel-body span {
    font-size: 12px;
  }
}
